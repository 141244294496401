.customer-page--scroll-container {
  overflow: auto;
  height: calc(100vh - 172px);
  padding: 0px 60px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.view-customer-page--accounts-title {
  margin: 8px 16px;
}

.paper-card--body {
  color: var(--color-blue-3);
  font-size: 12px;
  font-weight: 400;
}

.customer-details--row {
  display: flex;
  align-items: center;
  gap: 4px;
}

.customer-details--row:not(:last-child) {
  margin-bottom: 8px;
}

.customer-details--logo {
  margin-bottom: 20px;
}

#customer-page--map-container {
  width: 100%;
  height: 100%;
  max-height: 800px;
}

.customer-sub-accounts--body {
  display: flex;
  flex-direction: column;
  position: relative;
}

.customer-view-sub-accounts--link {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -32px;
}

.customer-view-consumption-resolution--select input.mantine-Select-input{
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dee2e6;
  border-radius: 0;
}

.customer-view-consumption-topline-metrics--container {
  display: flex;
  margin-bottom: 32px;
}


.customer-view-consumption-topline-metric--value.highlight {
  color: var(--color-blue-1);
}

.customer-view-consumption-topline-metric--value {
  font-size: 32px;
  font-weight: 700;
  color: var(--color-black-1);
  width: fit-content;
}

.customer-view-consumption-topline-metric-value--container {
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;
}

.customer-view-consumption-topline-metric--container {
  margin-right: 80px;
}

.customer-view-consumption-topline-metric-explanation--label {
  font-size: 12px;
  flex-shrink: 0;
  color: var(--color-grey-4);
  margin-left: 4px;
}

.customer-view-consumption-topline-metric-explanation--container {
  position: relative;
}

.customer-view-consumption-topline-metric--label {
  color: var(--color-grey-4);
  margin-left: 4px;
}

.customer-view-vs-forecast--container {
  position: absolute;
  bottom: -28px;
  display: flex;
  align-items: center;
}

.customer-view-vs-forecast--explanation {
  font-size: 10px;
  margin-left: 4px;
  color: var(--color-grey-4);
  white-space: nowrap;
}